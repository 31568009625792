<template>
  <v-container
    fluid
    class="custom-line-item-template pb-0"
    v-if="getPermission('line-item:create')"
  >
    <v-row v-if="allowItemSelection">
      <v-col lg="6" md="4" sm="4" class="pt-0">
        <v-autocomplete
          v-model.trim="item"
          :items="serverItemList"
          :search-input="serverSearch"
          clearable
          hide-details
          dense
          filled
          flat
          ref="search-item"
          :loading="serverSearchLoading || productLoading"
          label="Start typing to Search..."
          placeholder="Start typing to Search..."
          solo
          no-filter
          item-color="cyan"
          color="cyan"
          :disabled="productLoading || pageLoading"
          item-text="name"
          item-value="id"
          v-on:change="updateLineItem(item)"
          class="remove-border-radius hide-border-bottom"
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title v-if="serverSearchLoading">
                Getting results...
                <v-progress-linear
                  color="cyan"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-list-item-title>
              <v-list-item-title v-else>
                No Product<template v-if="!isPurchaseOrder"
                  ><template v-if="!isContract">/Service</template
                  >/Equipment</template
                >
                Found
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              :color="lodash.isEmpty(item.primary_image) ? 'cyan' : 'white'"
              size="70"
              class="headline font-weight-light white--text text-uppercase"
            >
              <template v-if="lodash.isEmpty(item.primary_image) === false"
                ><v-img :src="$assetAPIURL(item.primary_image)"></v-img
              ></template>
              <template v-else>{{ item.name.charAt(0) }}</template>
            </v-list-item-avatar>
            <v-list-item-content class="max-width-350px">
              <v-list-item-title
                class="font-size-16 text-truncate pb-1 pl-0 text-capitalize"
                v-text="item.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-if="item.description"
                class="font-size-14 text-truncate pb-1"
                v-text="item.description"
              ></v-list-item-subtitle>
              <template v-if="isProduct(item)">
                <v-list-item-subtitle class="font-size-14 pb-1">
                  <span class="font-weight-500">Serial Number: </span>
                  <template v-if="item.serial_number">
                    {{ item.serial_number }}</template
                  >
                  <template v-else>No Serial Number</template>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-size-14 pb-1">
                  <span class="font-weight-500">Part Number: </span>
                  <template v-if="item.part_number">
                    {{ item.part_number }}</template
                  >
                  <template v-else>No Part Number</template>
                </v-list-item-subtitle>
                <template v-if="false">
                  <v-list-item-subtitle class="font-size-14 pb-1"
                    ><span class="font-weight-500"
                      >Selling Cost / Unit Cost:</span
                    >
                    {{ formatNumber(item.selling_cost) }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="font-size-14"
                    ><span class="font-weight-500">Available Stock:</span>
                    {{
                      accountingUnFormat(item.available_stock)
                    }}</v-list-item-subtitle
                  >
                </template>
              </template>
              <template v-else>
                <v-list-item-subtitle class="font-size-14 pb-1"
                  ><span class="font-weight-500">Charges:</span>
                  {{ formatNumber(item.charges) }}</v-list-item-subtitle
                >
              </template>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="custom-border-bottom first-border">
      <v-col class="py-0">
        <label for="" class="font-size-16 pa-2"> Service </label>
      </v-col>
      <!-- <v-col class="py-0" md="2" v-if="isQtyAllowed">
        <label for="" class="font-size-16 pa-2">
          Qty.
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2" v-bind="attrs" v-on="on"
                >mdi-progress-question</v-icon
              >
            </template>
            <span>Maximum allowed Qty is 9999</span>
          </v-tooltip>
        </label>
      </v-col> -->
      <v-col class="py-0" md="2" v-if="isRateAllowed">
        <label for="" class="font-size-16 pa-2">Rate</label>
      </v-col>
      <v-col class="py-0" md="2" v-if="isDiscountAllowed && discountType == 2">
        <label for="" class="font-size-16 pa-2">Discount[$]</label>
      </v-col>
      <v-col class="py-0" md="2" v-if="isTaxAllowed && taxType == 2">
        <label for="" class="font-size-16 pa-2">Tax</label>
      </v-col>
      <v-col class="py-0" md="2">
        <label for="" class="font-size-16 pa-2">Total</label>
      </v-col>
    </v-row>
    <template v-if="lineItems.length < 1">
      <v-row class="custom-border-bottom">
        <v-col class="py-0" md="12">
          <p
            class="m-0 my-4 row-not-found text-center font-weight-500 font-size-16"
          >
            <img
              width="30"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mr-4"
            />
            Uhh... There are no line item at the moment.
          </p>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row
        v-for="(item, index) in lineItems"
        :key="index"
        :class="{ 'custom-border-bottom': !lineItems[index + 1] }"
      >
        <v-col>
          <template v-if="item.product">
            <v-layout>
              <template v-if="isGroupAllowed">
                <v-flex
                  class="flex-grow-0"
                  v-if="!isSingle"
                  style="max-width: 60px"
                >
                  <div class="v-text-field v-input d-block pt-0 mt-0">
                    <input
                      v-mask="'A'"
                      v-model.trim="item.group_me"
                      class="custom-grey-border p-1 d-block w-100 text-center"
                      style="padding: 16.5px 0px !important"
                      v-on:keyup="updateItemGroup(false)"
                    />
                  </div>
                </v-flex>
              </template>
              <v-flex class="flex-grow-1">
                <v-text-field
                  dense
                  filled
                  flat
                  color="cyan"
                  :loading="productLoading"
                  :disabled="productLoading || pageLoading"
                  label="Product"
                  hide-details
                  solo
                  readonly
                  :value="item.product.name"
                  class="remove-border-radius"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </template>
          <v-textarea
            v-model.trim="item.description"
            auto-grow
            dense
            filled
            flat
            :loading="productLoading"
            :disabled="productLoading || pageLoading"
            label="Description"
            solo
            color="cyan"
            class="remove-border-radius"
            hide-details
            row-height="20"
          ></v-textarea>
        </v-col>

        <v-col md="2" v-if="isRateAllowed">
          <template>
            <v-text-field
              v-model.trim.number="item.rate"
              dense
              filled
              flat
              type="number"
              min="0"
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading || !isGroup(item)"
              prepend-inner-icon="mdi-currency-usd"
              label="Rate"
              hide-details
              solo
              :readonly="!item.product"
              class="remove-border-radius prepend-currency"
              v-on:keyup="calculateTotalAmount(index)"
              v-on:change="calculateTotalAmount(index)"
            ></v-text-field>
          </template>
        </v-col>
        <v-col md="2" v-if="isDiscountAllowed && discountType == 2">
          <template>
            <v-text-field
              v-model.trim="item.discount"
              dense
              filled
              flat
              type="number"
              min="0"
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading || !isGroup(item)"
              prepend-inner-icon="mdi-currency-usd"
              label="Discount[$]"
              hide-details
              solo
              class="remove-border-radius prepend-currency"
              v-on:keyup="calculateTotalAmount(index)"
              v-on:change="calculateTotalAmount(index)"
            ></v-text-field>
          </template>
        </v-col>
        <v-col md="2" v-if="isTaxAllowed && taxType == 2">
          <template>
            <v-select
              :items="taxes"
              dense
              filled
              flat
              hide-details
              solo
              color="cyan"
              item-color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading || !isGroup(item)"
              placeholder="Tax Rate"
              item-text="name"
              item-value="id"
              :readonly="!item.product"
              v-model.trim="item.taxObject"
              class="remove-border-radius m-0 width-100"
              clearable
              return-object
              v-on:change="calculateTotalAmount(index)"
            >
              <template v-slot:selection="data">
                {{ data.item.name }} - [{{ data.item.percentage }}%]
              </template>
              <template v-slot:item="data">
                {{ data.item.name }} - [{{ data.item.percentage }}%]
              </template>
            </v-select>
          </template>
        </v-col>
        <v-col md="2">
          <template>
            <v-text-field
              v-model.trim="item.total"
              dense
              filled
              flat
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading || !isGroup(item)"
              prepend-inner-icon="mdi-currency-usd"
              label="Total"
              hide-details
              solo
              readonly
              class="remove-border-radius prepend-currency"
            ></v-text-field>
          </template>
          <template v-if="lineItems.length > 1">
            <div class="text-right">
              <v-btn
                tile
                :loading="productLoading || pageLoading"
                :disabled="pageLoading"
                class="custom-bold-button my-2"
                color="red"
                outlined
                small
                v-on:click="removeLineItem(index, item.id)"
                ><strong>Delete</strong>
              </v-btn>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="!isSingle">
        <v-col md="8" offset-md="4">
          <table class="width-100 vertical-align-middle">
            <tr>
              <td
                valign="center"
                class="font-size-16 py-3 font-weight-500"
                colspan="2"
              >
                Sub Total
              </td>
              <td
                valign="center"
                class="font-size-16 py-3 text-right"
                width="150"
              >
                {{ formatMoney(accountingToFixed(subTotalAmount, 2)) }}
              </td>
            </tr>
            <tr v-if="isDiscountAllowed && discountType === 1">
              <td
                valign="center"
                class="font-size-16 pb-2 font-weight-500"
                width="150"
              >
                Discount
              </td>
              <td valign="center" class="font-size-16 px-8">
                <v-text-field
                  dense
                  filled
                  flat
                  type="number"
                  min="0"
                  :disabled="pageLoading"
                  hide-details
                  solo
                  v-model.trim.number="discountValue"
                  class="discount-append remove-border-radius width-100 remove-append-outer-margin"
                  v-on:keyup="calculateGrandTotal"
                  v-on:change="calculateGrandTotal"
                >
                  <template v-slot:append-outer>
                    <v-select
                      :items="discountTypeList"
                      v-model.trim="discountValueType"
                      dense
                      filled
                      flat
                      :disabled="pageLoading"
                      hide-details
                      solo
                      item-text="type"
                      item-value="value"
                      color="cyan"
                      item-color="cyan"
                      class="discount-append-outer remove-border-radius m-0 width-80px hide-border-left"
                      v-on:change="calculateGrandTotal"
                    ></v-select>
                  </template>
                </v-text-field>
              </td>
              <td
                valign="center"
                class="font-size-16 pb-2 text-right"
                width="150"
              >
                -{{ formatMoney(accountingToFixed(discountAmount, 2)) }}
              </td>
            </tr>
            <tr v-if="isTaxAllowed && taxType == 1">
              <td valign="center" class="font-size-16 pb-2 font-weight-500">
                Tax Rate
              </td>
              <td valign="center" class="font-size-16 px-8">
                <v-select
                  :items="taxes"
                  dense
                  filled
                  flat
                  hide-details
                  solo
                  color="cyan"
                  item-color="cyan"
                  :loading="productLoading"
                  :disabled="productLoading || pageLoading"
                  placeholder="Tax Rate"
                  item-text="name"
                  item-value="id"
                  v-model.trim="taxObject"
                  class="remove-border-radius m-0 width-100"
                  clearable
                  return-object
                  v-on:change="calculateGrandTotal"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }} - [{{ data.item.percentage }}%]
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }} - [{{ data.item.percentage }}%]
                  </template>
                </v-select>
              </td>
              <td
                valign="center"
                class="font-size-16 pb-2 text-right"
                width="150"
              >
                {{
                  formatMoney(accountingToFixed(taxObject.percentage || 0.0, 2))
                }}
              </td>
            </tr>
            <tr v-if="isTaxAllowed">
              <td
                valign="center"
                class="font-size-16 py-3 font-weight-500"
                colspan="2"
              >
                Taxable Amount
              </td>
              <td
                valign="center"
                class="font-size-16 py-3 text-right"
                width="150"
              >
                {{ formatMoney(accountingToFixed(taxableAmount, 2)) }}
              </td>
            </tr>
            <tr v-if="isTaxAllowed">
              <td
                valign="center"
                class="font-size-16 py-3 font-weight-500"
                colspan="2"
              >
                Total Tax
              </td>
              <td
                valign="center"
                class="font-size-16 py-3 text-right"
                width="150"
              >
                {{ formatMoney(accountingToFixed(taxAmount, 2)) }}
              </td>
            </tr>
            <tr v-if="allowAdjustment">
              <td valign="center" class="font-size-16 pb-2 font-weight-500">
                Adjustment
              </td>
              <td valign="center" class="font-size-16 px-8">
                <v-text-field
                  dense
                  filled
                  flat
                  type="number"
                  min="0"
                  :disabled="pageLoading"
                  hide-details
                  solo
                  v-model.trim.number="adjustmentAmount"
                  class="remove-border-radius width-100"
                  v-on:keyup="calculateGrandTotal"
                  v-on:change="calculateGrandTotal"
                >
                </v-text-field>
              </td>
              <td
                valign="center"
                class="font-size-16 pb-2 text-right"
                width="150"
              >
                {{ formatMoney(accountingToFixed(adjustmentAmount, 2)) }}
              </td>
            </tr>
            <tr>
              <td
                valign="center"
                class="font-size-16 py-3 font-weight-500"
                colspan="2"
              >
                Grand Total
              </td>
              <td
                valign="center"
                class="font-size-16 py-3 text-right"
                width="150"
              >
                {{ formatMoney(accountingToFixed(grandTotalAmount, 2)) }}
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </template>
    <WarrantyManage
      v-on:remove:manage-warranty="removeWarranty"
    ></WarrantyManage>
  </v-container>
</template>
<script>
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import { QUERY, DELETE } from "@/core/services/store/request.module";
import { GET_TAXES } from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import WarrantyManage from "@/view/pages/partials/Warranty-Manage.vue";
import { WarrantyEventBus } from "@/core/lib/warranty/warranty.lib";
import Configuration from "@/core/config/app.config";

export default {
  name: "line-items",
  mixins: [ValidationMixin, CommonFunctionMixin],
  props: {
    isSingle: {
      type: Boolean,
      default: false,
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isProposal: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: [Number],
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    isNewItem: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    details: {
      type: Object,
      default: () => {
        return {};
      },
    },
    updateData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    WarrantyManage,
  },
  data() {
    return {
      item: {},
      discountAmount: 0,
      adjustmentAmount: 0,
      subTotalAmount: 0,
      taxableAmount: 0,
      discountValue: 0,
      taxAmount: 0,
      grandTotalAmount: 0,
      discountValueType: 1,
      serverItemList: [],
      serverSearch: null,
      serverSearchLoading: false,
      productLoading: true,
      searchTimeout: null,
      searchTimeoutLimit: 500,
      grandTotalTimeout: null,
      grandTotalTimeoutLimit: 200,
      taxObject: {},
      lineItems: [],
      taxes: [],
      uomList: [],
      discountTypeList: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
    };
  },
  watch: {
    serverSearch(previousValue, newValue) {
      if (previousValue != newValue) {
        this.getLineItems();
      }
    },
    updateData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isArray(param) && param.length > 0) {
          this.setUpdateData(param);
        }
      },
    },
  },
  methods: {
    setUpdateData(param) {
      const _this = this;
      _this.productLoading = false;
      for (let i = 0; i < param.length; i++) {
        const {
          id,
          group_me,
          group_active,
          group_primary,
          original_product,
          description,
          quantity,
          quantity_type,
          tax,
          discount,
          rate,
          total,
          warranty,
          warranty_unique_id,
          warranty_start_date,
          warranty_data,
          warranty_description,
        } = param[i];

        let warranty_object = [];
        if (_this.lodash.isArray(warranty_data) && warranty_data.length > 0) {
          warranty_object = warranty_data.map((row) => {
            return {
              id: row.id,
              warranty: _this.lodash.toSafeInteger(row.warranty) || null,
              value: _this.lodash.toSafeInteger(row.value),
              field: row.field,
              unique_id: null,
            };
          });
        }

        _this.lineItems.push({
          id,
          group_me,
          group_active,
          group_primary,
          type: "product",
          product: original_product,
          description,
          quantity,
          quantity_type,
          tax: tax ? tax.id : null,
          taxObject: tax ?? null,
          discount,
          rate,
          total,
          warranty: +!!warranty,
          warranty_line_item_id: null,
          warranty_object,
          warranty_option: {
            unique_id: warranty_unique_id,
            start_date: warranty_start_date,
            description: warranty_description,
          },
        });
      }

      if (_this.lodash.isEmpty(_this.details) === false) {
        _this.discountAmount = _this.details.discount_amount;
        _this.taxObject =
          _this.lodash.find(_this.taxes, {
            percentage: _this.details.tax_value,
          }) || {};
        _this.discountValue = _this.details.discount_value;
        _this.taxableAmount = _this.details.taxable_amount;
        _this.adjustmentAmount = _this.details.adjustment;
        _this.taxAmount = _this.details.tax_amount;
        _this.discountValueType = _this.details.discount_value_type;
      }

      _this.updateItemGroup(true);
    },
    isGroup(item) {
      if (this.isGroupAllowed && item.group_active) {
        if (item.group_primary) {
          return true;
        }
        return false;
      }
      return true;
    },
    getQtySuffix(product) {
      if (product) {
        return this.lodash.isEmpty(product.unit) === false
          ? product.unit.name
          : "";
      }
      return "";
    },
    isProduct(product) {
      if (product) {
        if (
          product.product_type == "goods" ||
          product.product_type == "equipment"
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    updateLineItem() {
      const _this = this;
      try {
        const item = _this.lodash.cloneDeep(_this.item);
        const warrantyObject = item.warranty_data.map((row) => {
          return {
            id: null,
            warranty: _this.lodash.toSafeInteger(row.id) || null,
            value: _this.lodash.toSafeInteger(row.value),
            field: row.field,
            unique_id: null,
          };
        });
        const taxObject =
          _this.lodash.isEmpty(item.tax) === false ? item.tax : null;
        const rate =
          item.product_type == "goods"
            ? _this.lodash.toNumber(item.selling_cost)
            : _this.lodash.toNumber(item.charges);
        _this.lineItems.push({
          id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          quantity: 1,
          quantity_type: null,
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          total: rate ?? 0.0,
          warranty: false,
          warranty_line_item_id: null,
          warranty_object: warrantyObject,
          warranty_option: new Object(),
        });
        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    updateItemGroup(isUpdate) {
      const _this = this;
      try {
        const groups = [];
        for (let i = 0; i < _this.lineItems.length; i++) {
          let { product, rate } = _this.lineItems[i];

          if (!isUpdate) {
            rate =
              product.product_type == "goods"
                ? _this.lodash.toNumber(product.selling_cost)
                : _this.lodash.toNumber(product.charges);
          }

          if (_this.lineItems[i].group_me) {
            _this.lineItems[i].group_me = _this.lineItems[i].group_me
              .toString()
              .toUpperCase();
            _this.lineItems[i].group_primary = true;
            _this.lineItems[i].group_active = true;
            if (_this.lodash.includes(groups, _this.lineItems[i].group_me)) {
              _this.lineItems[i].group_primary = false;
              // _this.lineItems[i].rate = 0.0;
            } else {
              groups.push(_this.lineItems[i].group_me);
            }
          } else {
            _this.lineItems[i].group_primary = false;
            _this.lineItems[i].group_active = false;
            _this.lineItems[i].rate = rate;
          }
        }
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    calculateTotalAmount(index) {
      try {
        const item = this.lineItems[index] ?? null;
        if (item) {
          const { rate, quantity, taxObject, discount } = item;
          if (!rate) return false;
          if (!quantity) return false;
          let subTotal = rate * quantity - (discount || 0);
          let taxId =
            this.lodash.isEmpty(taxObject) === false ? taxObject.id : null;
          let taxPercentage =
            this.lodash.isEmpty(taxObject) === false ? taxObject.percentage : 0;
          let taxAmount = 0;
          if (taxPercentage) {
            taxAmount = (subTotal * taxPercentage) / 100;
          }
          this.lineItems[index].tax = taxId;
          this.lineItems[index].total = subTotal + taxAmount;
          this.calculateGrandTotal();
        }
      } catch (error) {
        this.logError(error);
        this.removeLineItem(index);
      }
    },
    removeLineItem(index, id) {
      const _this = this;
      if (id > 0) {
        _this.$store
          .dispatch(DELETE, { url: "line-item/" + id })
          .then(() => {
            _this.lineItems.splice(index, 1);
            _this.calculateGrandTotal();
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.lineItems.splice(index, 1);
        _this.calculateGrandTotal();
      }
    },
    calculateGrandTotal() {
      const _this = this;
      const lineItems = _this.lodash.cloneDeep(_this.lineItems);
      let subTotalAmount = 0;
      let discountAmount = 0;
      let adjustmentAmount = this.adjustmentAmount ?? 0;
      let taxableAmount = 0;
      let taxAmount = 0;
      let grandTotalAmount = 0;
      let taxPercentage = [];

      for (let i = 0; i < lineItems.length; i++) {
        const { rate, quantity, discount, taxObject } = lineItems[i];
        const subTotal =
          _this.lodash.round(rate, 2) * _this.lodash.toSafeInteger(quantity);
        subTotalAmount =
          _this.lodash.round(subTotalAmount, 2) +
          _this.lodash.round(subTotal, 2);

        if (_this.discountType === 2) {
          discountAmount =
            _this.lodash.round(discountAmount, 2) +
            _this.lodash.round(discount, 2);
        }

        const taxable = _this.lodash.round(
          _this.lodash.round(subTotal, 2) - _this.lodash.round(discount, 2),
          2
        );

        taxableAmount =
          _this.lodash.round(taxableAmount, 2) + _this.lodash.round(taxable, 2);

        if (_this.taxType == 2 && _this.lodash.isEmpty(taxObject) === false) {
          const { percentage } = taxObject;
          taxPercentage.push(percentage);
          if (percentage && percentage > 0) {
            taxAmount =
              _this.lodash.round(taxAmount, 2) +
              _this.lodash.round(
                _this.lodash.round(_this.lodash.round(percentage, 2) / 100, 2) *
                  _this.lodash.round(taxable, 2),
                2
              );
          }
        }
      }

      if (_this.discountType === 1) {
        if (_this.discountValueType === 1) {
          /* Fixed Amount */
          discountAmount = _this.lodash.round(_this.discountValue, 2);
        } else if (_this.discountValueType === 2) {
          /* Percentage */
          discountAmount =
            (_this.lodash.round(subTotalAmount, 2) *
              _this.lodash.round(_this.discountValue, 2)) /
            100;
        }
        taxableAmount =
          _this.lodash.round(subTotalAmount, 2) -
          _this.lodash.round(discountAmount, 2);
      }

      if (_this.taxType == 1) {
        let { percentage } = _this.taxObject;
        if (percentage) {
          taxPercentage.push(percentage);
          taxAmount =
            _this.lodash.round(_this.lodash.round(percentage, 2) / 100, 2) *
            _this.lodash.round(taxableAmount, 2);
        }
      }

      grandTotalAmount =
        _this.lodash.round(
          _this.lodash.round(subTotalAmount, 2) +
            _this.lodash.round(taxAmount, 2) +
            _this.lodash.round(adjustmentAmount, 2),
          2
        ) - _this.lodash.round(discountAmount, 2);

      _this.discountAmount = _this.lodash.round(discountAmount, 2);
      _this.adjustmentAmount = _this.lodash.round(adjustmentAmount, 2);
      _this.subTotalAmount = _this.lodash.round(subTotalAmount, 2);
      _this.taxableAmount = _this.lodash.round(taxableAmount, 2);
      _this.taxAmount = _this.lodash.round(taxAmount, 2);
      _this.grandTotalAmount = _this.lodash.round(grandTotalAmount, 2);

      LineItemCalculationEventBus.$emit("update:line-item-calculation", {
        subTotal: _this.subTotalAmount || 0.0,
        discountValue: _this.discountValue || 0.0,
        taxType: _this.taxType || null,
        discountType: _this.discountType || null,
        discountValueType: _this.discountValueType || null,
        discountAmount: _this.discountAmount || 0.0,
        tax: _this.taxObject ? _this.taxObject.id : 0.0,
        taxPercentage:
          _this.lodash.head(_this.lodash.uniq(taxPercentage)) ||
          0.0 /* according to only singapore gst process. */,
        adjustmentAmount: _this.adjustmentAmount || 0.0,
        totalTax: _this.taxAmount || 0.0,
        total: _this.grandTotalAmount || 0.0,
      });

      LineItemEventBus.$emit("update:line-item", lineItems);
    },
    getLineItems() {
      const _this = this;
      if (!_this.allowItemSelection) {
        return false;
      }
      const search = _this.serverSearch ?? null;
      _this.serverSearchLoading = true;
      _this.serverItemList = [];
      clearTimeout(_this.searchTimeout);
      _this.searchTimeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/get-line-items",
            data: {
              search,
              purchaseorder: +_this.isPurchaseOrder,
              customer: _this.customer,
              contract: +_this.isContract,
            },
          })
          .then(({ data }) => {
            _this.serverItemList = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serverSearchLoading = false;
            _this.productLoading = false;
          });
      }, _this.searchTimeoutLimit);
    },
    getCustomLineItems(products) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (!products) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(QUERY, {
              url: "product/get-line-items",
              data: { products },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getTaxes() {
      const _this = this;
      _this.$store
        .dispatch(GET_TAXES)
        .then((response) => {
          _this.taxes = response.data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    removeWarranty(index) {
      this.lineItems[index].warranty = 0;
      WarrantyEventBus.$emit("close:manage-warranty");
      this.calculateGrandTotal();
    },
    manageWarranty(index) {
      const _this = this;
      const { warranty, product, warranty_object, warranty_option } =
        _this.lineItems[index];
      if (
        warranty &&
        _this.lodash.isEmpty(product) === false &&
        _this.lodash.isEmpty(warranty_object) === false
      ) {
        const defaultWarranty = warranty_object.map((row) => {
          return {
            current: index,
            warranty: _this.lodash.toSafeInteger(row.warranty),
            value: _this.lodash.toSafeInteger(row.value),
            field: row.field,
          };
        });
        if (!warranty_option.unique_id) {
          if (product && product.product_type === "equipment") {
            warranty_option.unique_id = product.serial_number;
          }
        }
        WarrantyEventBus.$emit("update:default-item-warranty", {
          current: index,
          data: {
            defaultItemWarranty: defaultWarranty,
            warrantyOption: warranty_option,
          },
        });
      }
    },
    updateWarrantyLineItem(row) {
      const _this = this;
      const {
        product_id,
        id,
        warranty_data,
        warranty_start_date,
        warranty_unique_id,
        warranty_description,
      } = row;
      _this.productLoading = true;
      _this
        .getCustomLineItems(product_id)
        .then((response) => {
          const product = _this.lodash.find(response, ["id", product_id]);
          _this.addCustomProduct({
            item: product,
            id: id,
            warranty: {
              data: warranty_data,
              description: warranty_description,
              start_date: warranty_start_date,
              unique_id: warranty_unique_id,
            },
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.productLoading = false;
        });
    },
    addCustomProduct(param) {
      const _this = this;
      try {
        const { item, id, warranty } = _this.lodash.cloneDeep(param);
        const warrantyActive = +!!(
          _this.lodash.isEmpty(warranty.data) === false &&
          _this.lodash.isArray(warranty.data) === true
        );
        let warrantyObject = [];
        if (warrantyActive) {
          warrantyObject = warranty.data.map((row) => {
            return {
              id: row.id,
              warranty: _this.lodash.toSafeInteger(row.warranty) || null,
              value: _this.lodash.toSafeInteger(row.value),
              field: row.field,
              unique_id: null,
            };
          });
        }
        const taxObject =
          _this.lodash.isEmpty(item.tax) === false ? item.tax : null;
        const rate =
          item.product_type == "goods"
            ? _this.lodash.toNumber(item.selling_cost)
            : _this.lodash.toNumber(item.charges);
        _this.lineItems.push({
          id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          quantity: 1,
          quantity_type: null,
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          total: rate ?? 0.0,
          warranty: warrantyActive,
          warranty_line_item_id: id,
          warranty_object: warrantyObject,
          warranty_option: {
            unique_id: warranty.unique_id,
            start_date: warranty.start_date,
            description: warranty.description,
          },
        });
        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  mounted() {
    this.getLineItems();
    this.getTaxes();
  },
  created() {
    const _this = this;

    LineItemEventBus.$on("update:warranty-line-item", (row) => {
      _this.updateWarrantyLineItem(row);
    });

    WarrantyEventBus.$on("update:manage-warranty", ({ current, data }) => {
      if (
        typeof current == "number" &&
        _this.lodash.isEmpty(data.defaultItemWarranty) === false &&
        _this.lodash.isArray(data.defaultItemWarranty) === true &&
        _this.lodash.isEmpty(_this.lineItems[current]) === false
      ) {
        _this.lineItems[current].warranty_object = data.defaultItemWarranty;
        _this.lineItems[current].warranty_option = data.warrantyOption;
        _this.calculateGrandTotal();
      }
    });
  },
  computed: {
    allowItemSelection() {
      if (this.isSingle && !this.isNewItem) {
        return false;
      }
      return true;
    },
    discountType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.discount_type)
        : 0;
    },
    taxType() {
      return this.lineItemConfig
        ? this.lodash.toSafeInteger(this.lineItemConfig.tax_type)
        : 0;
    },
    showEngineerRate() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_engineer_rate)
        : false;
    },
    allowAdjustment() {
      return this.lineItemConfig
        ? !!+this.lodash.toSafeInteger(this.lineItemConfig.allow_adjustment)
        : false;
    },
    isGroupAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_item_group) {
          return false;
        }
        if (this.isPurchaseOrder) {
          if (this.lineItemConfig.purchase_order_item_group) {
            return true;
          }
          return false;
        }
        if (this.isTicket) {
          if (this.lineItemConfig.ticket_item_group) {
            return true;
          }
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_item_group) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_item_group) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_item_group) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_item_group) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isQtyAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_qty) {
          return false;
        }
        if (this.isPurchaseOrder) {
          if (this.lineItemConfig.purchase_order_qty) {
            return true;
          }
          return false;
        }
        if (this.isTicket) {
          if (this.lineItemConfig.ticket_qty) {
            return true;
          }
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_qty) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_qty) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_qty) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_qty) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isRateAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_rate) {
          return false;
        }
        if (this.isPurchaseOrder) {
          if (this.lineItemConfig.purchase_order_rate) {
            return true;
          }
          return false;
        }
        if (this.isTicket) {
          if (this.lineItemConfig.ticket_rate) {
            return true;
          }
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_rate) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_rate) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_rate) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_rate) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isDiscountAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_discount) {
          return false;
        }
        if (this.isPurchaseOrder) {
          if (this.lineItemConfig.purchase_order_discount) {
            return true;
          }
          return false;
        }
        if (this.isTicket) {
          if (this.lineItemConfig.ticket_discount) {
            return true;
          }
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_discount) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_discount) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_discount) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_discount) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
    isTaxAllowed() {
      if (
        this.lineItemConfig &&
        this.lodash.isEmpty(this.lineItemConfig) === false
      ) {
        if (!this.lineItemConfig.allow_tax) {
          return false;
        }
        if (this.isPurchaseOrder) {
          if (this.lineItemConfig.purchase_order_tax) {
            return true;
          }
          return false;
        }
        if (this.isTicket) {
          if (this.lineItemConfig.ticket_tax) {
            return true;
          }
          return false;
        }
        if (this.isQuotation) {
          if (this.lineItemConfig.quotation_tax) {
            return true;
          }
          return false;
        }
        if (this.isInvoice) {
          if (this.lineItemConfig.invoice_tax) {
            return true;
          }
          return false;
        }
        if (this.isProposal) {
          if (this.lineItemConfig.proposal_tax) {
            return true;
          }
          return false;
        }
        if (this.isContract) {
          if (this.lineItemConfig.contract_tax) {
            return true;
          }
          return false;
        }
      }
      return true;
    },
  },
  beforeMount() {
    this.lineItemConfig = Configuration.getLineItemConfig();
  },
};
</script>
