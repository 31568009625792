<template>
  <v-container fluid class="custom-line-item-template">
    <table class="width-100">
      <thead>
        <tr>
          <th class="font-size-16 pb-2">Product</th>
          <th width="300" class="pl-5 font-size-16 pb-2">
            To Be Received Qty.
          </th>
          <th width="300" class="pl-5 font-size-16 pb-2">Received Qty.</th>
          <th width="300" class="pl-5 font-size-16 pb-2">Created Time</th>
        </tr>
        <tr>
          <td colspan="5" class="custom-border-bottom first-border"></td>
        </tr>
      </thead>
      <tbody v-if="receivedLineItem.length > 0">
        <tr v-for="(item, index) in receivedLineItem" :key="index">
          <td class="font-size-16 font-weight-500">
            <div class="detail-line-item py-4">
              <v-chip
                small
                label
                class="custom-status font-weight-600 custom-grey-border text-uppercase"
                text-color="white"
                color="cyan darken-1"
              >
                Product
              </v-chip>
              <p class="m-0 pt-1 text-capitalize custom-nowrap-ellipsis">
                {{ item.product.name }}
              </p>
            </div>
          </td>
          <td>
            <v-text-field
              v-model.trim.number="item.quantity"
              dense
              filled
              flat
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading"
              label="Qty."
              hide-details
              solo
              readonly
              class="remove-border-radius px-2 remove-border-radius hide-border-bottom"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model.trim.number="item.received_qty"
              dense
              filled
              flat
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading"
              label="Qty."
              hide-details
              solo
              readonly
              class="remove-border-radius px-2"
            ></v-text-field>
          </td>
          <td>
            <TableActivity v-if="!lodash.isEmpty(item.added_by)" :data="item">
              <template v-slot:display_name>
                {{ item.added_by.display_name }}
              </template>
              <template v-slot:date_time>
                {{ item.created_at }}
              </template>
              <template v-slot:format_date_time>
                {{ formatDateTime(item.added_at) }}
              </template>
            </TableActivity>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <p
              class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
            >
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image"
              />
              Uhh... There are no line item received at the moment.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import { GET } from "@/core/services/store/request.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "received-line-item",
  mixins: [CommonMixin],
  props: {
    purchaseOrder: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  components: {
    TableActivity,
  },
  data: () => {
    return {
      formValid: true,
      productLoading: false,
      pageLoading: true,
      receivedLineItem: new Array(),
    };
  },
  methods: {
    getReceivedItems() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + _this.purchaseOrder + "/received",
        })
        .then(({ data }) => {
          _this.receivedLineItem = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getReceivedItems();
  },
};
</script>
