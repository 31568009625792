<template>
  <v-card class="mx-auto custom-product-hover-info" max-width="600">
    <div class="product-hover-detail font-size-16 gray-background p-6">
      <table class="width-100">
        <thead>
          <tr class="cursor-pointer" v-on:click="pushToDetail">
            <td colspan="3" class="font-size-18">{{ product.name }}</td>
            <td align="right">
              <v-icon right color="white">mdi-chevron-right</v-icon>
            </td>
          </tr>
        </thead>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          tag="tbody"
          style="max-height: 20vh; position: relative"
        >
          <tr>
            <td class="pt-2">Product #</td>
            <td class="pt-2">
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.barcode ? product.barcode : " - " }}
              </p>
            </td>
          </tr>
          <tr v-if="false">
            <td>UPC</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.upc ? product.upc : " - " }}
              </p>
            </td>
            <td>SKU</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.sku ? product.sku : " - " }}
              </p>
            </td>
          </tr>
          <tr v-if="false">
            <td>HSN</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.hsn ? product.hsn : " - " }}
              </p>
            </td>
            <td>EAN</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.ean ? product.ean : " - " }}
              </p>
            </td>
          </tr>
          <tr v-if="false">
            <td>ISBN</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.isbn ? product.isbn : " - " }}
              </p>
            </td>
            <td>Taxable</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.is_taxable ? "Yes" : "No" }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Part Number</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.part_number ? product.part_number : " - " }}
              </p>
            </td>
            <td>Sellable</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.is_sellable ? "Yes" : "No" }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Brand</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.brand ? product.brand.text : " - " }}
              </p>
            </td>
            <td>Manufacturer</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.manufacturer ? product.manufacturer.text : " - " }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.category ? product.category.text : " - " }}
              </p>
            </td>
            <template v-if="product.product_type == 'goods'">
              <td>Unit</td>
              <td>
                <p class="m-0 custom-nowrap-ellipsis">
                  {{ product.unit ? product.unit.text : " - " }}
                </p>
              </td>
            </template>
          </tr>
          <tr>
            <td>Supplier</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.supplier ? product.supplier.display_name : " - " }}
              </p>
            </td>
            <td v-if="product.product_type != 'goods'">Charges</td>
            <td v-if="product.product_type != 'goods'">
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.charges) }}
              </p>
            </td>
          </tr>
          <tr v-if="product.product_type == 'goods'">
            <td>Selling Cost / Unit Cost</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.selling_cost) }}
              </p>
            </td>
            <td>Purchase Cost</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.purchase_cost) }}
              </p>
            </td>
          </tr>
          <tr v-if="false">
            <td>Asset Value</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatMoney(product.asset_value) }}
              </p>
            </td>
            <td>Accounting</td>
            <td class="text-uppercase">
              <p class="m-0 custom-nowrap-ellipsis">{{ product.accounting }}</p>
            </td>
          </tr>
          <tr v-if="false">
            <td>Track Batch</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.track_batch ? "Yes" : "No" }}
              </p>
            </td>
            <td>Manage Stock</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ product.manage_stock ? "Yes" : "No" }}
              </p>
            </td>
          </tr>
          <tr>
            <td>Weight</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatNumber(product.weight) }} kg
              </p>
            </td>
            <td>Width</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatNumber(product.width) }} mm
              </p>
            </td>
          </tr>
          <tr>
            <td>Length</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatNumber(product.length) }} mm
              </p>
            </td>
            <td>Height</td>
            <td>
              <p class="m-0 custom-nowrap-ellipsis">
                {{ $accountingJS.formatNumber(product.height) }} mm
              </p>
            </td>
          </tr>
        </perfect-scrollbar>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  methods: {
    pushToDetail() {
      /*if (this.product.product_type == "goods") {
        this.$router.push(
          this.getDefaultRoute("product.detail", {
            params: { id: this.product.id }
          })
        );
      } else if (this.product.product_type == "service") {
        this.$router.push(
          this.getDefaultRoute("service.detail", {
            params: { id: this.product.id }
          })
        );
      }*/
    },
  },
};
</script>
