<template>
  <v-card class="mx-auto custom-product-hover-info">
    <div class="product-hover-detail font-size-16 gray-background p-6">
      <v-row>
        <v-col :md="lineItem.warranty_qr_code ? 6 : 12">
          <table class="width-100 max-width-768px">
            <thead>
              <tr class="cursor-pointer">
                <td colspan="4" class="font-size-18">
                  {{ lineItem.warranty_in_days }} Days Warranty
                </td>
              </tr>
            </thead>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              tag="tbody"
              style="max-height: 20vh; position: relative"
            >
              <tr>
                <td class="pt-2" width="200">Warranty Start Date</td>
                <td class="pt-2">{{ lineItem.warranty_start_date }}</td>
              </tr>
              <tr>
                <td class="pt-2" width="200">Warranty End Date</td>
                <td class="pt-2">{{ lineItem.warranty_end_date }}</td>
              </tr>
              <tr>
                <td class="pt-2" width="200">Serial No.</td>
                <td class="pt-2">
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ lineItem.warranty_unique_id }}
                  </p>
                </td>
              </tr>
              <tr>
                <td class="pt-2" width="200">Description</td>
                <td class="pt-2">
                  <read-more
                    class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="
                      lineItem.warranty_description || '<em>No Description</em>'
                    "
                    link="#"
                    less-str="read less"
                    :max-chars="100"
                  >
                  </read-more>
                </td>
              </tr>
              <tr>
                <td class="pt-2" width="200">Duration</td>
                <td class="pt-2">
                  <span
                    v-for="(warranty, index) in lineItem.warranty_data"
                    :key="index"
                    >{{ warranty.value }} {{ warranty.field
                    }}<template v-if="lineItem.warranty_data[index + 1]"
                      >,
                    </template>
                  </span>
                </td>
              </tr>
            </perfect-scrollbar>
          </table>
        </v-col>
        <v-col md="6" v-if="lineItem.warranty_qr_code">
          <v-img
            contain
            :src="lineItem.warranty_qr_code"
            max-height="250"
            max-width="250"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    lineItem: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
};
</script>
